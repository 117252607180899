<template>
  <b-modal
    v-model="showModal"
    title="Send Ticket in Email"
    centered
    @hidden="$emit('modal-closed')"
    @ok="submitHandler"
  >
    <b-card-text>
      Are you sure you want to send ticket {{ ticket.ticket_id }} in email?
    </b-card-text>

    <template #modal-footer="{ ok, cancel }">
      <b-button
        variant="secondary"
        @click="cancel()"
      >
        Cancel
      </b-button>
      <b-button
        variant="primary"
        :disabled="sending"
        @click="ok()"
      >
        Send
        <b-spinner
          v-if="sending"
          small=""
        />
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import axios from 'axios'
import {
  BModal, BCardText, BButton, BSpinner,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BModal,
    BCardText,
    BButton,
    BSpinner,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
    isBuyer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModal: true,
      sending: false,
    }
  },
  methods: {
    async submitHandler(event) {
      event.preventDefault()
      this.sending = true

      try {
        let response = null
        if (this.isBuyer) {
          response = '' // TODO: buyer ticket email is pending
        } else {
          response = await axios.post(`/tickets/send_supplier_ticket_email/${this.ticket.id}/`)
        }
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response?.data?.message || 'Ticket email sent successfully',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.showModal = false
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error?.response?.data?.error || 'Error sending ticket email',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        this.sending = false
      }
    },
  },
}
</script>
