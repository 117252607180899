<template>
  <b-modal
    v-model="showModal"
    title="Add Supplier Ticket"
    size="xl"
    no-close-on-backdrop
    @hidden="$emit('modal-closed')"
    @ok="submit"
  >
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>

    <b-alert
      variant="danger"
      :show="loadingError ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ loadingError }}
        </p>
      </div>
    </b-alert>

    <div v-if="!loading && !loadingError">

      <b-form
        @submit.prevent="submit"
      >
        <validation-observer
          ref="ticketForm"
        >
          <b-row>

            <b-col
              md="12"
            >
              <validation-provider
                #default="{ errors }"
                name="Invoice Number"
                :vid="'item_number'"
                rules="required"
              >
                <b-form-group
                  label="Invoice Number"
                  :state="errors.length > 0 ? false : null"
                >
                  <custom-v-select
                    v-model="invoiceId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="invoiceOptions"
                    label="text"
                    placeholder="Select Invoice Number"
                    :state="errors.length > 0 ? false:null"
                    :reduce="option => option.id"
                    @search="onInvoiceSearch"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>

            </b-col>

            <b-col
              v-if="invoiceDetails"
              md="12"
            >
              <h6>Invoice Details</h6>
              <invoice-info
                :item="invoiceDetails"
              />
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Departments"
                vid="departments"
                rules="required"
              >
                <b-form-group
                  label="Departments"
                  :state="errors.length > 0 ? false : null"
                >
                  <custom-v-select
                    v-model="departments"
                    multiple
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="displayDepartmentOptions"
                    label="text"
                    placeholder="Select Departments"
                    :clearable="false"
                    :reduce="option => option.id"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <ticket-discrepancies
            v-model="discrepancies"
            :item-options="itemOptions"
          />

        </validation-observer>
      </b-form>
    </div>

    <template #modal-footer="{ ok, cancel }">
      <b-button
        variant="secondary"
        @click="cancel()"
      >
        Cancel
      </b-button>
      <b-button
        variant="primary"
        :disabled="saving || loading || loadingError ? true : false"
        @click="ok()"
      >
        Submit
        <b-spinner
          v-if="saving"
          small
          label="Small Spinner"
        />
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal, BRow, BCol, BFormGroup, BButton, BForm, BSpinner, BAlert,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import axios from 'axios'
import { debounce } from 'lodash'

// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

import CustomVSelect from '@/components/UI/CustomVSelect.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import dateMixin from '@/mixins/dateMixin'
import InvoiceInfo from '@/components/Invoices/Invoice/InvoiceInfo.vue'
import TicketDiscrepancies from '@/components/Tickets/TicketDiscrepancy/TicketDiscrepancies.vue'

export default {
  components: {
    CustomVSelect,
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BAlert,
    InvoiceInfo,
    TicketDiscrepancies,
  },
  mixins: [dateMixin],
  data() {
    return {
      itemOptions: [],
      showModal: true,
      loading: false,
      loadingError: null,
      saving: false,
      invoiceDetails: null,
      invoiceId: null,
      invoiceOptions: [],
      discrepancies: [],
      departments: [],
      departmentOptions: [],
    }
  },
  computed: {
    displayDepartmentOptions() {
      return this.departmentOptions.map(option => ({
        id: option,
        text: option.charAt(0).toUpperCase() + option.slice(1),
      }))
    },
  },
  watch: {
    invoiceId() {
      if (this.invoiceId) {
        this.fetchInvoiceDetails()
      } else {
        this.invoiceDetails = null
      }
    },
  },
  created() {
    this.initializeForm()
  },
  methods: {
    onInvoiceSearch: debounce(function dummyName(search, loading) {
      this.refreshInvoiceOptions(search, loading)
    }, 500),
    refreshInvoiceOptions(search, loading) {
      loading(true)
      axios.get('/invoices/invoices_options', {
        params: {
          search_term: search,
        },
      }).then(res => {
        this.invoiceOptions = res.data.data
        loading(false)
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error?.response?.data?.detail || 'Error fetching invoice options',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        loading(false)
      })
    },
    async fetchInvoiceDetails() {
      try {
        const res = await axios.get(`/invoices/invoice/${this.invoiceId}/`)
        this.invoiceDetails = res.data
      } catch (error) {
        this.loadingError = error?.response?.data?.detail || 'Error fetching invoice details'
      }
    },
    async initializeForm() {
      this.loading = true

      try {
        const res = await axios.get('/invoices/invoices_options')
        this.invoiceOptions = res.data.data
      } catch (error) {
        this.loadingError = error?.response?.data?.detail || 'Error fetching invoice search options'
        this.loading = false
      }

      try {
        const res = await axios.get('/item_options')
        this.itemOptions = res.data.data
      } catch (error) {
        this.loadingError = error?.response?.data?.detail || 'Error fetching item options'
        this.loading = false
        return
      }

      try {
        const res = await axios.get('/tickets/department_options')
        this.departmentOptions = res.data.departments
      } catch (error) {
        this.loadingError = error?.response?.data?.detail || 'Error fetching department options'
        this.loading = false
        return
      }

      this.loadingError = null
      this.loading = false
    },
    async submit(event) {
      event.preventDefault()

      const isFormValid = await this.$refs.ticketForm.validate()
      if (!isFormValid) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please correct the form errors',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return
      }

      if (!this.discrepancies.length) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Atleast one discrepancy is required to create a ticket.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return
      }

      this.saving = true

      const formData = {
        invoice: this.invoiceId,
        departments: this.departments,
        discrepancies: this.discrepancies,
      }

      axios.post('/tickets/supplier-ticket/', formData)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ticket created successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$refs.ticketForm.reset()
          this.saving = false
          this.showModal = false
          this.$emit('saved')
        }).catch(error => {
          this.$refs.ticketForm.reset()

          const statusCode = error?.response?.status
          const serverErrors = error?.response?.data
          if (statusCode === 400 && serverErrors) {
            this.$refs.ticketForm.setErrors(serverErrors)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Please correct the form errors',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.response?.data?.detail || 'Error saving ticket',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
          this.saving = false
        })
    },
  },
}

</script>
