<template>
  <div>

    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>

    <b-alert
      variant="danger"
      :show="loadingError ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ loadingError }}
        </p>
      </div>
    </b-alert>

    <b-row class="mt-2">
      <b-col
        align="right"
      >
        <b-button
          variant="outline-primary"
          class="mb-1"
          @click="addTicketHandler"
        >
          Add Supplier Ticket
        </b-button>
      </b-col>
    </b-row>

    <ticket-form
      v-if="displayAddTicketForm"
      @modal-closed="closeTicketForm"
      @saved="fetchItems"
    />

    <b-table
      v-if="!loading && !loadingError"
      :items="results"
      :fields="tableFields"
      responsive
      striped
      hover
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      class="compact-table"
      no-local-sorting
      show-empty
      empty-text="No records found"
      @sort-changed="sortingChanged"
    >
      <template #head(select)>
        <b-form-checkbox
          v-model="allRecordsSeleted"
          :disabled="results.length === 0"
          @change="toggleRecordsSelection"
        />
      </template>
      <template #cell(select)="data">
        <b-form-checkbox
          v-model="selectedRecords"
          :value="data.item.id"
        />
      </template>
      <template #cell(invoice__invoice_no)="data">
        {{ data.item.invoice__invoice_no }}
      </template>

      <template #cell(invoice__company_group)="data">
        {{ data.item.invoice__company_group }}
      </template>

      <template #cell(invoice__supplier)="data">
        {{ data.item.invoice__supplier }}
      </template>

      <template #cell(status)="data">
        <status-icon :value="data.item.status" />
      </template>

      <template #cell(closed_at)="data">
        <span v-if="data.item.closed_at">
          {{ formatedDate(data.item.closed_at, 'DD-MM-YYYY hh:mm:ss A') }}
        </span>
      </template>

      <template #cell(created_at)="data">
        <span v-if="data.item.created_at">
          {{ formatedDate(data.item.created_at, 'DD-MM-YYYY hh:mm:ss A') }}
        </span>
      </template>

      <template
        #cell(email_sent)="data"
      >
        <span v-if="data.item.email_sent">
          {{ data.item.email_sent }}
        </span>
        <span v-else>
          0
        </span>
      </template>

      <template #cell(actions)="data">
        <feather-icon
          v-b-tooltip.hover="{boundary:'window'}"
          title="View Details"
          icon="EyeIcon"
          size="20"
          class="cursor-pointer mr-1"
          @click="displayDetails(data.item.id)"
        />

        <feather-icon
          v-if="!downloadingTicketIds.includes(data.item.id)"
          v-b-tooltip.hover="{boundary:'window'}"
          title="Download"
          icon="DownloadIcon"
          size="20"
          class="cursor-pointer mr-1"
          @click="downloadTicket(data.item.id, data.item.ticket_id)"
        />

        <b-spinner
          v-if="downloadingTicketIds.includes(data.item.id)"
          small
          label="Small Spinner"
          class="mr-2"
        />

        <feather-icon
          v-b-tooltip.hover="{boundary:'window'}"
          title="Send Email"
          icon="SendIcon"
          size="20"
          class="cursor-pointer mr-1"
          @click="sendTicket = data.item"
        />

      </template>
    </b-table>

    <detailed-pagination
      v-if="!loading && !loadingError"
      :total-items="totalItems"
      :per-page="perPage"
      :current-page="currentPage"
      @page-changed="(page) => {currentPage = page}"
    />

    <ticket-details
      v-if="openTicketId"
      :ticket-id="openTicketId"
      @modal-closed="openTicketId=null"
      @onDiscrepancyClose="handleDiscrepancyClose"
      @onCloseDialog="handleCloseDialog"
    />

    <send-ticket
      v-if="sendTicket"
      :ticket="sendTicket"
      @modal-closed="sendTicket = null"
    />
  </div>
</template>

<script>
import {
  BSpinner, BTable, BAlert, VBTooltip, BFormCheckbox, BCol, BRow, BButton,
} from 'bootstrap-vue'
import axios from 'axios'
import download from 'downloadjs'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import bus from '@/bus'
import dateMixin from '@/mixins/dateMixin'
import DetailedPagination from '@/components/UI/DetailedPagination.vue'
import StatusIcon from '@/components/UI/StatusIcon.vue'
import SendTicket from '@/components/Tickets/SendTicket.vue'
import TicketDetails from './TicketDetails.vue'
import TicketForm from './TicketForm.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BSpinner,
    BTable,
    BAlert,
    StatusIcon,
    BFormCheckbox,
    BCol,
    BRow,
    BButton,
    TicketDetails,
    DetailedPagination,
    TicketForm,
    SendTicket,
  },
  mixins: [dateMixin],
  props: {
    biltyId: {
      type: Number,
      required: false,
      default() {
        return null
      },
    },
    filters: {
      type: Object,
      required: false,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      loading: true,
      loadingError: null,
      results: [],
      tableFields: [
        { key: 'select' },
        { key: 'ticket_id', label: 'TICKET ID', sortable: true },
        { key: 'invoice__invoice_no', label: 'INVOICE NO.', sortable: true },
        { key: 'invoice__company_group', label: 'COMPANY GROUP', sortable: true },
        { key: 'invoice__supplier', label: 'SUPPLIER NAME', sortable: true },
        { key: 'discrepancy_count', label: 'Discrepancy Count', sortable: true },
        { key: 'created_at', label: 'CREATED AT', sortable: true },
        { key: 'created_by__fullname', label: 'CREATED BY', sortable: true },
        { key: 'status', label: 'STATUS', sortable: false },
        { key: 'closed_at', label: 'CLOSED AT', sortable: true },
        { key: 'actions', label: 'Actions' },
      ],
      currentPage: 1,
      perPage: 50,
      totalItems: 0,
      sortBy: 'ticket_id',
      sortDesc: true,
      displayInvoiceDetailsId: null,
      addTicketDetails: null,
      editTicketDetails: null,
      allRecordsSeleted: false,
      selectedRecords: [],
      openTicketId: null,
      displayAddTicketForm: null,
      downloadingTicketIds: [],
      sendTicket: null,
      isCloseDiscrepancyAvailable: false,
    }
  },
  watch: {
    currentPage() {
      this.fetchItems()
    },
    filters() {
      if (this.currentPage !== 1) {
        this.currentPage = 1
      } else {
        this.fetchItems()
      }
    },
  },
  created() {
    bus.$on('invoices/invoiceList/refresh', this.fetchItems)
    this.fetchItems()
  },
  destroyed() {
    bus.$off('invoices/invoiceList/refresh', this.fetchItems)
  },
  methods: {
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy
      this.sortDesc = ctx.sortDesc
      this.fetchItems()
    },
    fetchItems() {
      this.loading = true
      axios.post('/tickets/filtered_supplier_tickets', {
        page: this.currentPage,
        per_page: this.perPage,
        sort_by: this.sortBy,
        sort_desc: this.sortDesc,
        filters: { ...this.filters },
      })
        .then(res => {
          this.results = res.data.results
          this.totalItems = res.data.count
          this.loadingError = null
          this.loading = false
        }).catch(error => {
          this.loading = false
          const errorResponse = error?.response
          if (errorResponse && errorResponse.status === 404 && this.currentPage > 1) {
            this.currentPage -= 1
            this.fetchItems()
          } else {
            this.loadingError = error?.response?.data?.detail || 'Error fetching tickets list'
          }
        })
    },
    toggleRecordsSelection(checked) {
      this.selectedRecords = checked ? this.results.map(result => result.id) : []
    },
    displayDetails(ticketId) {
      this.openTicketId = ticketId
    },
    addTicketHandler() {
      this.displayAddTicketForm = true
    },
    closeTicketForm() {
      this.displayAddTicketForm = false
    },
    downloadTicket(recordId, ticketId) {
      this.downloadingTicketIds.push(recordId)

      axios.get(`/tickets/download_supplier_ticket/${recordId}`, {
        responseType: 'blob',
      })
        .then(res => {
          const contentType = res.headers['content-type']
          download(res.data, ticketId, contentType)
          this.downloading = false

          this.downloadingTicketIds = this.downloadingTicketIds.filter(id => id !== recordId)
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error?.response?.data?.error || 'Error downloading supplier ticket',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          this.downloadingTicketIds = this.downloadingTicketIds.filter(id => id !== recordId)
        })
    },
    handleDiscrepancyClose() {
      if (!this.isCloseDiscrepancyAvailable) {
        this.isCloseDiscrepancyAvailable = true
      }
    },

    async handleCloseDialog() {
      if (this.isCloseDiscrepancyAvailable) {
        await this.fetchItems()
        this.isCloseDiscrepancyAvailable = false
      }
    },
  },

}
</script>
