<template>
  <b-card no-body>
    <b-card-header>
      <h4>Supplier Tickets</h4>
    </b-card-header>

    <b-card-body>
      <ticket-filters @filtersUpdated="filtersChangeHandler" />
      <tickets-list :filters="filters" />
    </b-card-body>

  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody,
} from 'bootstrap-vue'

import TicketsList from '@/components/Tickets/SupplierTickets/TicketsList.vue'
import TicketFilters from '@/components/Tickets/SupplierTickets/TicketFilters.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    TicketFilters,
    TicketsList,
  },
  data() {
    return {
      filters: {},
    }
  },
  methods: {
    filtersChangeHandler(filterData) {
      this.filters = filterData
    },
  },
}
</script>
